import React from 'react';
import NotFoundPage from 'components/NotFoundPage';

const NotFound = (props) => {
  return (
    <NotFoundPage />
  );
};

export default NotFound;
