import React from 'react';
import { StyledNotFound, Text, SmallText } from './styles';
import BackLink from 'components/UI/BackLink';
import { navigate } from 'gatsby';

const NotFoundPage = () => {
  return (
    <StyledNotFound>
      <Text>404</Text>
      <SmallText>La page n'a pas été trouvée</SmallText>
      <BackLink handleOnClick={() => navigate('/')} body="Accueil" />
    </StyledNotFound>
  );
};

export default NotFoundPage;
